footer {
  background-color: $footerBg;
  width: 100%;
  padding: 85px 5%;
  height: $footer-Height;
  position: relative;
  @include clearfix();
  font: {
    weight: $f700;
  }
  // position: fixed;
  // bottom: 0;
  .row {
    h3 {
      color: $light-gray;
      font-size: $font-size-m;
    }
    > div {
      width: 50%;
      float: left;
      &.contact {
        float: right;
      }
      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 50px;
      }
    }
    .logo {
      margin-top: 7px;
    }
    .menu {
      ul li {
        a {
          font-size: $font-size-m * 2;
        }
      }
    }
    .contact {
      .btn {
        margin-top: 10px;
      }
    }
    .etc {
      ul li {
        display: inline-block;
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
    .lang {
      position: absolute;
      right: 7%;
      bottom: 50px;
    }
    bottom: 0;
  }

  @media screen and (max-width: 650px) {
    padding: 40px 5%;
    height: auto;
    .row {
      h3 {
        font-size: $font-size-r;
      }
      .menu {
        ul li {
          a {
            font-size: $font-size-xs * 2.29;
          }
        }
      }
      .etc {
        h2 {
          display: none;
        }

        ul li {
          display: block;
          a {
            font-size: $font-size-r;
          }
        }
      }
      .contact {
        margin-bottom: 5px;
      }
    }
  }
  @media screen and (max-width: 414px) {
    .row {
      > div {
        width: 100%;
        margin-bottom: 40px;
        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 40px;
        }
      }
      .etc {
        ul li {
          display: block;
          &:last-child {
            margin-top: 20px;
          }
        }
      }
      .lang {
        position: static;
      }
    }
  }
}
