header {
  background: $mainBg;
  left: 0;
  width: 100%;
  position: fixed;
  @include layoutPadding();
  top: 0px;
  z-index: 1000;

  a {
    font-weight: $f700;
  }

  .header-inner {
    position: relative;
    height: $header-Height;
    border-bottom: 2px solid $white-gray;
    @include clearfix();
  }
  .mina-logo {
    float: left;
    line-height: $header-Height;

    svg {
      vertical-align: middle;
    }
  }
  .menu {
    position: absolute;
    line-height: $header-Height;
    left: 50%;
    transform: translateX(-50%);
    li {
      display: inline-block;
      &:not(:first-child) {
        margin-left: 30px;
      }
    }
  }
  .link-login {
    line-height: $header-Height;
    float: right;
  }
  .header-tt {
    line-height: $header-Height;
    font-weight: $f700;
  }

  .userBtn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 35px;
    height: 35px;
    border-radius: 35px;
    background-color: $lime;
    font-weight: $f700;
    line-height: 35px;
  }
  .popup-menu {
    visibility: hidden;
    opacity: 0;
    &.on {
      visibility: visible;
      opacity: 1;
      top: calc(100% + 19px);
    }
    position: absolute;
    transition: 0.23s;
    z-index: 10;
    left: 50%;
    top: calc(100% + 30px);
    transform: translateX(-50%);
    width: 140px;
    background-color: $dark-gray;
    padding: 15px 20px;
    border-radius: $borderR;
    text-align: left;
    &:after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: calc(50% - 5px);
      width: 0;
      height: 0;
      border-right: 5px solid transparent;
      border-bottom: 10px solid $dark-gray;
      border-left: 5px solid transparent;
    }
    li {
      position: relative;
      line-height: 21px;
      text-align: center;
      transition: 0.2s;
      a,
      span {
        color: $white-gray;
        font-size: $font-size-s;
      }
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      &:hover {
        li,
        a {
          color: $lime;
        }
      }
    }
  }

  .btn-ham {
    display: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .mobile_nav {
    display: none;
    ul {
      margin-top: 40px;
      li {
        font-size: $font-size-m * 2;

        &:last-child {
          font-size: $font-size-m;
          margin-top: 80px;
        }
      }
    }
    .contact {
      position: fixed;
      width: 100%;
      bottom: 5vh;
      h3 {
        font-weight: $f700;
        font-size: $font-size-r;
        margin-bottom: 10px;
        color: $light-gray;
      }
      .lang {
        float: right;
        font-weight: $f700;
        margin-top: 10px;
        margin-right: 10%;
      }
    }
  }
  .opacity {
    a {
      color: rgba(250, 250, 250, 0.3);

      &:hover {
        color: $white-gray;
      }
    }
  }
  @media screen and (max-width: 1680px) {
    .header-tt {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  @media screen and (max-width: 1254px) {
    .popup-menu {
      width: 130px;
      left: -25px;
      &:after {
        left: 70%;
      }
    }
  }
  @media screen and (max-width: 650px) {
    .btn-ham {
      display: block;
    }
    .menu,
    .link-login {
      display: none;
    }
    .mobile_nav.on {
      display: block;
      height: 100vh;
    }
  }
}
