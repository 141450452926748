@charset "utf-8";

/* main */
.main {
  background-color: $mainBg;
  position: relative;
  z-index: 2;
  padding-top: 64px;
  padding-bottom: 200px;
  min-height: calc(100% - 474px);
}

/* index 페이지 */

.index {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  section {
    .main-video {
      width: 100%;
      padding-top: 56.4%;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
      }
      > div {
        z-index: 1;
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        iframe {
          border-radius: 5px;
          width: 100%;
          height: 100%;
        }
      }
    }

    h2 {
      font-size: $font-size-xl;
    }
    &:nth-child(2) {
      margin-bottom: 200px;
    }
    @include layoutPadding();
    &.hero {
      padding-bottom: 100px;
      overflow-x: hidden;
      .marquee {
        position: relative;
        display: flex;
        white-space: nowrap;
        div {
          display: flex;
          animation: marquee 30s linear infinite;
        }
      }
      &.leon {
        background: $lime;
        .logo-wrap {
          svg {
            path {
              fill: $black-10;
            }
          }
        }
        .main-desc {
          padding: 10px 0;

          span {
            color: $black-10;
          }
        }
      }
    }

    &.tutorial-section {
      .bgmsIs {
        padding: 200px 0;

        display: flex;
        align-items: center;
        .margin-top {
          margin-top: 20px;
        }
      }
      &.bg-odd {
        overflow: hidden;
        background-color: #171b21;
      }

      .flex-end {
        justify-content: flex-end;
        flex-direction: row-reverse;

        .tutorial-desc {
          margin-left: 120px;
        }
      }
      .flex-start {
        justify-content: flex-start;
        .tutorial-desc {
          // margin-right: 235px;
          width: 55%;
        }
      }
      .margin-right {
        .tutorial-desc {
          // margin-right: 310px;
        }
      }
    }

    .step {
      video {
        border-radius: 5px;
        max-width: 540px;
        max-height: 304px;
      }
    }

    .center {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        max-width: 659px;
      }
    }

    .download_btn_wrap {
      text-align: center;
      font-weight: normal;
      .down-info {
        font-size: $font-size-s;
        color: $gray-3;
        svg {
          margin-right: 6px;
        }
        path {
          fill: #888888;
        }
        a {
          // text-decoration: underline;
          color: #888888;
        }
      }
    }

    .logo-wrap {
      position: relative;
      svg {
        width: 100%;
        height: 100%;
        margin: 7vh 0;
        path {
          fill: $lime;
        }
      }
      span {
        position: absolute;
        background: #ea3414;
        color: $white-gray;
        width: 34%;
        top: 48%;
        right: -6%;
        display: inline-block;
        padding: 15px 0 15px 15px;
        font: {
          size: $font-size-m;
          weight: $f700;
        }
      }
    }
    .main-desc {
      white-space: nowrap;
      span {
        position: relative;

        font: {
          size: $font-size-l;
          weight: $f700;
        }
        color: $lime;
        margin-right: 60px;
      }
    }
    .btn_wrap {
      margin-top: 40px;
      text-align: center;
      .btn {
        &:first-of-type {
          margin-bottom: 10px;
        }
      }
    }

    .bgmsIs {
      .desc {
        margin: 150px 0 80px;
        span {
          display: block;
          font-weight: $f700;
          color: $gray-3;
        }
        h2 {
          margin: 0 0 20px;
          padding-top: 60px;
        }
      }
      .tutorial-desc {
        span {
          display: block;
          font-weight: $f700;
          color: $gray-3;
        }
        h2 {
          margin: 0 0 20px;
          padding-top: 60px;
        }
      }
      .bg-video {
        border-radius: $borderR;
      }
    }
    .slide-box {
      position: relative;
      background: $lime;

      padding: 70px 0 60px 0;
      color: #171717;
      font-weight: $f700;
      overflow: hidden;
      border-radius: $borderR;
      .marquee {
        position: relative;
        display: flex;
        white-space: nowrap;
        overflow: hidden;
        position: relative;
        div {
          display: flex;
          animation: marquee 10s linear infinite;
        }
      }
      span {
        margin: 0 !important;
      }
      .item {
        position: relative;
        // animation: slide_left 5s infinite linear;
        // transform: translateX(100%);
        .slide-text {
          // word-spacing: 10px;
          font-size: 1.725em;
          margin-left: 25px !important;
        }
        .btn {
          margin-left: 10px;
        }
      }

      .download_btn_wrap {
        .down-info {
          a {
            &:hover {
              color: #1a1f26;
              path {
                fill: #1a1f26;
              }
            }
          }
        }
        margin-top: 60px;
        .btn_wrap {
          margin-top: 0;
        }
      }
    }
  }
  .ads_area {
    max-width: 1390px;
    margin: 0 auto;
  }
}

/* Error 페이지 */

.error {
  height: 100vh;
  width: 100%;
  background-color: $mainBg;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include layoutPadding();

  h2 {
    font-size: $font-size-r * 11.5;
    line-height: 2;
  }
  .contents {
    color: $gray-9;
    a {
      color: $gray-9;
      text-decoration: underline;
      &:hover {
        color: #fff;
      }
    }
  }
  .btn {
    margin-top: 130px;
  }
}

/* Download 페이지 */

.download {
  .center {
    text-align: center;
  }
  h3 {
    font-size: $font-size-l * 0.83;
    padding-bottom: 20px;
  }
  section {
    .bgmsIs-info {
      padding-top: 200px;
    }
    @include layoutPadding();
    &:nth-child(2) {
      margin-top: 100px;
    }
    &.bg-odd {
      overflow: hidden;
      background-color: #171b21;
    }
    .bgmsIs {
      padding: 200px 0;
      .desc {
        margin-bottom: 80px;
        span {
          display: block;
          font-weight: $f700;
          color: $gray-3;
        }
        h2 {
          margin: 0 0 20px;
          padding-top: 60px;
        }
      }
      .bg-video {
        border-radius: $borderR;
      }
    }
    .btn_wrap {
      margin-top: 60px;
    }

    .down-info {
      &.margin-top {
        margin-top: 10px;
      }
    }
  }
  h4 {
    font-size: $font-size-m;
  }
  p {
    // margin-bottom: 60px;
  }

  .down-info {
    font-size: $font-size-s;
    color: $gray-3;
    a {
      color: $gray-3;

      svg {
        margin-right: 6px;
        path {
          fill: $gray-3;
        }
      }
      &:hover {
        color: $lime;
        path {
          fill: $lime;
        }
      }
    }
  }
  .btn {
    &:not(:first-of-type) {
      margin-left: 10px;
    }
  }
  .article-wrap {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    .article {
      border-top: 2px solid $lime;
      display: inline-block;
      max-width: 360px;
      width: 31%;
      padding-top: 10px;
      span {
        font-weight: $f700;
        color: $lime;
      }
      h4 {
        margin: 20px 0 40px;
      }
    }
  }
}

/* Policy 페이지 */

.policy {
  @include layoutPadding();

  h2 {
    span {
      display: block;
      font-size: 16px;
      color: $light-gray;
    }
  }
  h3 {
    margin-top: 40px;
    font-size: $font-size-m * 1.2;
  }
  .top-desc {
    li {
      margin-bottom: 5px;
    }
    border-bottom: 1px solid $dark-gray;
    padding-bottom: 20px;
  }
  dl {
    dt {
      margin-top: 40px;
      margin-bottom: 10px;
    }
    dd {
      margin-bottom: 5px;
      .depth {
        margin-top: 5px;
        padding-left: 10px;
      }
      .depth-3 {
        margin-left: 40px;
      }
      ol {
        &.decimal {
          > li {
            list-style: decimal;
            margin-left: 20px;
          }
        }
        li {
          margin-bottom: 5px;
          h4 {
            margin-left: 22px;
            position: relative;
            span {
              position: absolute;
              right: calc(100% + 5px);
            }
            margin-bottom: 5px;
            font-weight: $f400;
          }
          p {
            margin-left: 20px;
            margin-bottom: 5px;
          }
        }
      }
      ul {
        margin-left: 20px;
        > li {
          list-style: square;
          margin-bottom: 5px;
        }
      }
    }
  }
}

/* login 페이지 */

.login {
  @include layoutPadding();

  text-align: center;
  padding-bottom: 300px;
  .btn {
    margin: 40px 0;
    padding: 15px 60px;
  }
  svg {
    vertical-align: bottom;
    margin-right: 10px;
  }
  p {
    font-size: $font-size-s;
    color: $gray-3;
    a {
      text-decoration: underline;
      color: $gray-3;
      &:hover {
        color: #fff;
      }
    }
  }
}
/* auth 페이지 */
.auth {
  @include layoutPadding();
  display: flex;
  justify-content: center;
  .auth-sub {
    margin-top: 10px;
  }
  .auth-inner {
    svg {
      margin: 100px 0 40px;
    }
    h2 {
      margin-bottom: 20px;
    }
    p {
      span {
        margin-top: 10px;
        color: $gray-6;
        font-size: $font-size-s;
        a {
          text-decoration: underline;
          color: $gray-6;
          &:hover {
            color: #fff;
          }
        }
      }
    }
    .donwload-link {
      color: $gray-3;
      font-size: $font-size-xs;
      a {
        text-decoration: underline;
        color: $gray-3;
        &:hover {
          color: #fff;
        }
      }
    }
    .btn {
      margin-top: 60px;
      &:not(:first-of-type) {
        margin-left: 10px;
      }
    }
  }
}
/* player 페이지 */
.player {
  @include layoutPadding();
  margin: 40px 0;
  .video-container {
    .player-info {
      padding: 30px;
      position: absolute;
      z-index: 10;
      top: 0;
      bottom: 0;
      width: 100%;

      .player-bg {
        position: absolute;
        background: linear-gradient(to top, transparent 70%, rgba(0, 0, 0, 0.5));
        z-index: 10;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
      }
      .player-info-tt {
        position: relative;
        z-index: 11;
        float: left;
        width: 80%;
        @include ellipsisMulti(3);
      }
      .btn-wrap {
        position: absolute;
        z-index: 11;
        right: 30px;
        .btn-circle:first-of-type {
          margin-right: 10px;
        }
      }
    }

    position: relative;
    width: 100%;
    height: auto;
    padding-top: 50%;
    border-radius: $borderR;
    overflow: hidden;
    iframe {
      z-index: 1;
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }
}

/* myAccount 페이지 */
.myAccount {
  h2 {
    margin-bottom: 60px;
    font-size: $font-size-l;
  }
  @include clearfix();
  @include layoutPadding;
  .article {
    width: 50%;
    float: left;
    h2 {
      font-size: $font-size-r;
      margin-bottom: 15px;
    }
    h3 {
      margin-bottom: 10px;
    }
    .userEmail {
      color: $gray-9;
      font-size: $font-size-m;
    }
    .deleteEmail {
      color: $gray-6;
      .btn {
        margin-top: 40px;
      }
    }
  }
}

/* myChannel 페이지 */

.myChannel {
  @include layoutPadding();

  .channel-wrapper {
    &:not(:last-of-type) {
      border-bottom: 1px solid $dark-gray;
    }
    .channel-title-header {
      position: relative;
      h2 {
        display: inline-block;
        padding-bottom: 10px;
      }
      > span {
        position: absolute;
        margin-left: 15px;
        bottom: 29px;
        display: inline-block;
        width: 27px;
        height: 27px;
        line-height: 27px;
        border-radius: 27px;
        background-color: $white-gray;
        color: #171717;
        text-align: center;
        font: {
          size: $font-size-xs;
          weight: $f700;
        }
        &.invited {
          background-color: $lime;
        }
      }
    }
    .channel-noti {
      color: $gray-9;
    }
    .channerl-wrap {
      margin-top: 40px;
      @include clearfix();
      li {
        width: calc(25% - 20px);
        float: left;
        margin-right: 20px;
        margin-bottom: 50px;
        cursor: pointer;
        &:hover .thumbnail-inner {
          transform: scale(1.1);
        }
      }
      #thumbnail,
      .createChannel {
        width: 100%;
        position: relative;
        &::after {
          content: '';
          display: block;
          padding-bottom: 100%;
        }
        img,
        > div {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: $borderR;
        }
      }
      .createChannel {
        border: 1px solid #292f38;
        border-radius: $borderR;
        transition: border-color 0.1s;
        cursor: pointer;
        &:hover {
          border-color: $white-gray;
        }
        > div svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .channel {
        #thumbnail {
          position: relative;
          overflow: hidden;
          border-radius: $borderR;
          .thumbnail-inner {
            @include background-img;
            transition: 0.3s;
          }
          .icon-wrap {
            position: absolute;
            top: 15px;
            left: 15px;
            z-index: 5;
            svg {
              float: left;
            }
          }
          &::before {
            position: absolute;
            content: '';
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            background: rgb(0, 0, 0);
            background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
          }
        }
        .channel-info {
          margin-top: 10px;
          // height: 80px;
          position: relative;
          @include clearfix();
          .channel-info-tt {
            width: 100%;
            .channelName {
              @include ellipsisMulti(2);
              font-weight: $f700;
              margin-bottom: 3px;
            }
            .channelDesc {
              font-size: $font-size-s;
              .ownerNickname {
                @include ellipsis();
                display: inline-block;
                max-width: 83%;
              }
              span {
                vertical-align: top;
              }
            }

            float: left;
          }
          .inviteBtn-wrap {
            float: left;
            margin-top: 20px;
            button:first-of-type {
              margin-right: 10px;
            }
          }
        }
        &.ghost {
          #thumbnail {
            filter: grayscale(100%);
          }
          .channel-info {
            color: $gray-6;
          }
        }
      }
    }
  }
  .create-channel-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #303030;
    background: rgba(27, 31, 38, 0.4);
    z-index: 50;
    .create-channel {
      position: absolute;
      background-color: $black-08;
      width: 318px;
      border-radius: $borderR;
      padding: 20px;
      top: 50%;
      left: 50%;
      margin-left: -164px;
      margin-top: -232.5px;
      @include box-shadow;
      @include fadeInUP;
      @include fadeInLeft;

      h3 {
        margin-bottom: 15px;
        color: $gray-d;
      }
      #selectUrl {
        display: none;
      }
      .imgWrap {
        position: relative;
        cursor: pointer;
        border-radius: $borderR;
        @include background-img;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          bottom: 0;
          transition: 0.43s;
        }
        &:hover::before {
          background-color: rgba(0, 0, 0, 0.2);
        }
        &::after {
          content: '';
          display: block;
          padding-bottom: 100%;
        }
        .btn-Plus {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0;
          transition: 0.1s;
        }
        &:hover .btn-Plus {
          opacity: 1;
        }
      }
      #createName {
        width: 100%;
        padding: 8px 15px;
        margin: 10px 0 5px;
        box-sizing: border-box;
        background-color: #31343a;
        border-radius: $borderR;
        color: $gray-d;
      }
      #create-button {
        float: right;
        margin-top: 10px;
      }
      .input-count {
        text-align: right;
        line-height: 1;
        color: $gray-d;
        font : {
          weight: $f700;
          size: $font-size-xs;
        }
        font-weight: $f700;
      }
    }
  }
  .myChannel-empty {
    .btn {
      margin-top: 80px;
    }
  }
}

/* Channel 인트로 */

.channel.Intro {
  @include layoutPadding();

  h2 {
    font-size: $font-size-l;
  }
  .player-info {
    p {
      color: $gray-9;
      margin-bottom: 10px;
      span {
        color: $gray-6;
        font-size: $font-size-s;
      }
    }
  }
  .btn {
    margin-top: 60px;
    margin-right: 10px;
  }
  .donwload-link {
    display: block;
    color: $gray-6;
    font-size: $font-size-xs;
    margin-top: 10px;
    a {
      text-decoration: underline;
      color: $gray-6;
      &:hover {
        color: #fff;
      }
    }
  }
}

/* invite 인트로 */

.invite {
  @include layoutPadding();

  table {
    margin: 0 auto;
    &.contents {
      .logo {
        padding-top: 2em;
      }

      .h1-text {
        font-size: 2em;
        font-weight: 700;
        padding: 1.5em 0;
      }

      .h2-text {
        font-size: 1em;
        font-weight: 700;
        padding: 1em 0 0.5em;
      }

      .footer {
        padding-top: 4em;
        color: $gray-6;
        p {
          margin: 0;
          padding-top: 10px;
          border-top: 1px solid #333333;
        }
      }
      button {
        margin: 4em 0 0;
      }

      a {
        color: $gray-6;
      }
    }
  }
}

// 카카오 애드핏
.adfit {
  width: 540px;
  // height:50px;
  height: 100%;
  float: left;
}

.popup-container-outter {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  .popup-container {
    width: 591px;
    margin: 100px auto 0;
    background: $black-10;
    border-radius: $borderR;

    > h3 {
      font-size: $font-size-m;

      padding: {
        top: 30px;
        left: 40px;
      }
    }

    > p {
      font-size: $font-size-r;

      padding: {
        left: 30px;
      }
    }

    .popup-message {
      margin: 30px 40px 10px;
      padding: 20px 15px 20px 30px;
      background: #31343a;
      border-radius: $borderR;

      p {
        white-space: pre-line;
        font-size: $font-size-s;
        line-height: 2em;
      }
    }

    .btn-wrap {
      position: relative;
      text-align: right;
      padding: 50px 40px 30px;
      overflow: hidden;

      > div {
        position: absolute;
        bottom: 30px;
      }

      button {
        border-radius: 3px;
        font-size: $font-size-xs;
        font-weight: bold;
        box-sizing: border-box;
        vertical-align: top;
        color: $black-10;

        &.btn-close {
          float: left;
        }

        &:last-child {
          background-color: $lime;
        }

        &.update-btn {
          margin-left: 10px;
          padding: 8px 14px;
        }

        &.update-recommend-btn {
          padding: 7px 14px;
        }
      }
    }
  }
}

/********************************************************************************************************
  미디어 쿼리
*********************************************************************************************************/

@media screen and (max-width: 1300px) {
  .wrapB {
    width: 100%;
  }
  /* error 페이지 */

  .error {
    h2 {
      font-size: $font-size-r * 8;
    }
  }
}

@media screen and (max-width: 1124px) {
  .player {
    .video-container {
      .player-info {
        .player-info-tt {
        }
        .btn-wrap {
        }
      }
    }
  }
  /* myAccount 페이지 */
  .myAccount {
    .article {
      width: 100%;
      &:last-child {
        margin-top: 50px;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  /* index 페이지 */

  .index {
    section {
      .logo-wrap {
        svg {
          margin: 100px 0 40px;
        }
        span {
          padding: 10px 0 10px 15px;
          width: 50%;
          top: 75%;
          font : {
            size: $font-size-s;
          }
        }
      }
      .main-desc {
        white-space: nowrap;
        overflow: hidden;
        span {
          font: {
            size: $font-size-r;
          }
        }
      }

      &.tutorial-section {
        .bgmsIs {
          width: 100%;
          display: block;
          margin-left: 0;
        }

        .flex-start {
          .tutorial-desc {
            width: 100%;
            margin: 0;
          }
        }
        .flex-end {
          .tutorial-desc {
            margin: 0;
          }
        }
        .margin-right {
          .tutorial-desc {
            margin: 0;
          }
        }
      }
      .step {
        margin-top: 60px;
        &.margin-right {
          margin-right: 0;
        }
        &.margin-left {
          margin-left: 0;
          // margin-top: 120px;
        }
        video {
          max-width: 100%;
          max-height: unset;
          height: auto;
        }
      }
    }
  }
  /* error 페이지 */
  .error {
    h2 {
      font-size: $font-size-r * 6;
    }
  }

  /* myChannel 페이지 */
  .myChannel {
    .channel-wrapper {
      .channerl-wrap {
        li {
          width: calc(33.33% - 20px);
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  body {
    font-size: 14px;
    // h2 { font-size: $font-size-m;}
    // h3, p, span{font-size: $font-size-s;}
    // .btn{font-size: $font-size-xs;}
    .wrapB h2 {
      padding-top: 80px;
    }
  }

  .whiteSpace-pre {
    white-space: normal;
  }

  .player {
    .video-container {
      .player-info {
        padding: 15px;
        .player-info-tt {
          h1 {
            font-size: $font-size-r;
          }
        }
        .btn-wrap {
          top: 15px;
          right: 5px;
        }
      }
    }
  }

  /* main */
  .main {
    padding-bottom: 0;
    margin-bottom: 100px;
    min-height: calc(100% - 329px);
  }
  /* index 페이지 */

  .index {
    overflow: hidden;
    section {
      .bgmsIs {
        .tutorial-desc {
          h2 {
            margin: 0;
            padding-top: 40px;
          }
        }
        .desc {
          margin-top: 80px;
          margin-bottom: 60px;
          h2 {
            padding-top: 0;
            padding-bottom: 0;
          }
        }
        .tutorial-section {
          h2 {
            margin: 0;
          }
        }
      }
      &.hero {
        padding-bottom: 80px;
      }
      &.tutorial-section {
        .bgmsIs {
          width: 100%;
          display: block;
          margin-left: 0;
          padding: 80px 0;
        }
        .flex-start {
          .tutorial-desc {
            margin: 0;
          }
        }
        .flex-end {
          .tutorial-desc {
            margin: 0;
          }
        }
        .margin-right {
          .tutorial-desc {
            margin: 0;
          }
        }
      }
      &:nth-child(2) {
        margin-bottom: 80px;
      }

      .step {
        margin-top: 60px;
        &.margin-right {
          margin-right: 0;
        }
        &.margin-left {
          margin-left: 0;
          // margin-top: 120px;
        }
        video {
          max-width: unset;
          width: 100%;
        }
      }

      .btn_wrap {
        .btn {
          display: block;
          margin: 0 auto;
          &:first-of-type {
            margin-right: auto;
            margin-bottom: 10px;
          }
        }
      }

      .slide-box {
        margin-top: 0;
        padding: 40px 0;
        color: #171717;
        .item {
          slide-text {
            vertical-align: middle;
            margin-right: 10px;
            font-size: $font-size-m;
          }
          .btn {
            margin-left: 10px;
            &:nth-of-type(2) {
              margin-right: 80px;
            }
          }
          // > div:first-child {
          //   margin-right: 50px;
          // }
        }
        .download_btn_wrap {
          margin-top: 40px;
          .btn {
            display: inline-block;
            min-width: auto;
          }
        }
        .marquee {
          div {
            position: relative;
            animation: marquee 15s linear infinite;
          }
        }
      }
    }
  }
  /* login 페이지 */
  .login {
    p {
      font-size: $font-size-xs;
    }
    padding-bottom: 0;
  }
  /* error 페이지 */

  .error {
    h2 {
      font-size: $font-size-r * 4;
    }
  }

  /* download 페이지 */
  .download {
    .whiteSpace-pre {
      white-space: pre-line;
    }
    p {
      // margin-bottom: 40px;
    }
    .btn {
      display: block;
      text-align: center;
      &:first-of-type {
        margin-bottom: 10px;
        margin-right: 0;
      }
      &:not(:first-of-type) {
        margin-left: 0;
      }
    }
    .article-wrap {
      display: block;
      .article {
        width: 100%;
        max-width: unset;
        margin-bottom: 40px;
        h4 {
          margin: 20px 0;
        }
        &:last-of-type {
          margin-bottom: 0;
          p {
            margin-bottom: 0;
          }
        }
      }
    }
    .section {
      .bgmIs {
        padding: 80px 0;
        .desc {
          h2 {
            margin: 0;
          }
        }
        &-info {
          padding-top: 80px;
        }
      }
    }
  }

  /* myChannel 페이지 */
  .myChannel {
    .channel-wrapper {
      &:not(:last-of-type) {
        > li:last-of-type {
          margin-bottom: 80px;
        }
      }
      .channel-title-header {
        > span {
          font-size: 10px;
          width: 22px;
          height: 22px;
          line-height: 22px;
          bottom: 24px;
          margin-left: 10px;
        }
      }
      .channerl-wrap {
        .channel {
          #thumbnail {
            .icon-wrap {
              top: 10px;
              left: 10px;
              svg {
                width: 13px;
              }
            }
          }
        }
        li {
          width: calc(50% - 20px);
          margin-bottom: 30px;
        }
      }
    }
  }

  /* auth success 페이지 */
  .auth {
    .auth-inner {
      svg {
        margin-top: 80px;
      }
      .btn {
        margin-top: 10px;
        width: 100%;
        &:not(:first-of-type) {
          margin-left: unset;
        }
      }
    }
  }
}

@mixin social-icon($color) {
  color: #3e4550;

  .tooltip {
    background: $color;
    color: currentColor;

    &:after {
      border-top-color: $color;
    }
  }
}

/* Help */
.help-container {
  position: fixed;
  bottom: 20px;
  right: 20px;

  @include social-icon($lime);

  z-index: 2;
  .tooltip-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #3e4550;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .tooltip {
    white-space: pre;
    display: block;
    position: absolute;
    top: -5px;
    right: -60px;
    padding: 3px 8px;
    border-radius: 5px;
    font-size: 0.8rem;
    font-weight: bold;
    opacity: 0;
    pointer-events: none;
    text-transform: uppercase;
    transform: translate(-50%, -100%);
    transition: all 0.3s ease;
    z-index: 1;

    &:after {
      display: block;
      position: absolute;
      bottom: 1px;
      right: 3%;
      width: 0;
      height: 0;
      content: '';
      border: solid;
      border-width: 8px 5px 0 5px;
      border-color: transparent;
      transform: translate(-50%, 100%);
      border-top-color: $lime;
    }
  }
  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 1;
      transform: translate(-50%, -150%);
    }
  }
  &:active {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5) inset;
  }
}

@media screen and (max-width: 450px) {
  /* error 페이지 */

  .error {
    font-size: $font-size-xs;
    h2 {
      font-size: $font-size-r * 3;
    }
  }

  /* invite 페이지 */

  .invite {
    table.contents .h2-text {
      font-size: 1.5em;
    }

    table.contents {
      font-size: 14px;
      width: 90%;
    }
  }
  .download {
    .whiteSpace-pre {
      white-space: unset;
    }
  }
}

@media screen and (max-width: 414px) {
  /* main */
  .main {
    min-height: calc(100% - 524px);
  }
  /* login */
  .login {
    p {
      br {
        display: none;
      }
    }
  }
}
