/* COLOR */

$black-10: #1a1f26;
$black-08: #171b21;

$gray-3: #54575c;
$gray-6: #666;
$gray-9: #999;
$gray-d: #ddd;
$light-gray: #54575c;
$dark-gray: #292f38;
$white-gray: #fafafa;
$lime: #ddfc10;
$red: #d26a5b;
$mainBg: $black-10;
$footerBg: $black-08;

/* FONT */

$font-size-xl: 2.5em; /*40px*/
$font-size-l: 1.875em; /*30px*/
$font-size-m: 1.25em; /*20px*/
$font-size-r: 1em; /*16px*/
$font-size-s: 0.875em; /*14px*/
$font-size-xs: 0.75em; /*12px*/

$f400: 400;
$f700: 700;

$border-Rl: 4px;

/* animation-duration */

$ani-duration: 10s;

::selection {
  background-color: #ddfc10;
  color: #1a1f26;
}

h1,
h2,
h3 {
  font-weight: $f700;
}

h2 {
  font-size: 1.875em; /*30px*/
}
h3 {
  font-size: 1em; /*16px*/
}
/* ETC */

$borderR: 5px;

@mixin wrap {
  width: 100%;
  float: left;
}

@mixin layoutPadding {
  padding: 0 5%;
}

/* 레이어 아웃 */

$header-Height: 64px;
$footer-Height: 474px;

/*  */
html,
body,
#__next {
  height: 100%;
}

body {
  font-size: 16px;
  overflow: auto;
  line-height: 1.5em;
  color: #fff;
  background-color: $mainBg;
}

.wrapB {
  // width: 1200px;
  width: 1200px;
  margin: 0 auto;
}

/* img */

@mixin background-img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: normal;
}

@mixin ellipsisMulti($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 버튼 백그라운드 색상 및 호버 색상 */

@mixin bgcolor-hovereffect($background-color, $hover-color) {
  background: linear-gradient(to left, $background-color 50%, $hover-color 50%) no-repeat;
  background-size: 300% 100%;
  background-position: 100% 0;
}

/* layout */
.wrapB {
  h2 {
    padding-top: 98px;
    padding-bottom: 20px;
  }
}

/* 버튼 */

.slide-box {
  .btn {
    &:hover {
      color: #ddfc10;
    }
    &-white {
      @include bgcolor-hovereffect(#fff, #1a1f26);
      color: #1a1f26;
    }
  }
}

.btn {
  display: inline-block;
  color: $gray-d;
  padding: 15px 20px;
  border-radius: 3px;
  font : {
    weight: $f700;
    size: $font-size-s;
  }
  position: relative;
  @include bgcolor-hovereffect(#292f38, $lime);
  transition: 0.3s;

  &:hover {
    background-position: left bottom !important;
    color: #171717;
  }
  &-white {
    @include bgcolor-hovereffect(#fff, $lime);
    color: #171717;
  }
  &-red {
    @include bgcolor-hovereffect($red, #ce5a48);
    &:hover {
      color: #fff;
    }
  }
  &-black {
    @include bgcolor-hovereffect(#171717, $lime);
    color: #fff;
    &:hover {
      color: #171717;
    }
  }
  &-accept {
    @include bgcolor-hovereffect($lime, #fff);
    color: #171717;
    padding: 8px 10px;
    font-size: $font-size-s;
  }
  &-reject {
    border: 1px solid rgba(250, 250, 250, 0.3);
    padding: 8px 10px;
    @include bgcolor-hovereffect(transparent, rgba(250, 250, 250, 0.2));
    font-size: $font-size-s;
    &:hover {
      color: #fff;
    }
  }
  &-down {
    min-width: 197px;
  }
  &-small {
    padding: 7px 14px;
  }
  &-disabled {
    opacity: 0.5;
    cursor: default;
    @include bgcolor-hovereffect($dark-gray, $dark-gray);
    &:hover {
      color: #ddd;
    }
  }
}

.btn-circle {
  display: inline-flex;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: $black-10;
  justify-content: center;
  align-items: center;
}

/* 햄버거 버튼 */

.btn-ham {
  $size: 20px;
  $color: #fff;

  @mixin pill() {
    content: '';
    position: absolute;
    width: 100%;
    height: 12%;
    background: $color;
    transition: all 0.5s cubic-bezier(0.1, 0.9, 0, 1.2);
  }

  display: block;
  position: relative;
  width: $size;
  height: $size;

  .bun {
    &:before {
      @include pill();
      top: 10%;
      right: 0;
    }

    &:after {
      @include pill();
      bottom: 10%;
      left: 0;
    }

    .burger {
      position: absolute;
      display: flex;
      align-items: center;
      height: $size;
      width: $size;

      &:before,
      &:after {
        @include pill();
      }
    }
  }

  &:hover ~ .bun {
    &:before,
    &:after,
    :before,
    :after {
      background: darken($color, 5);
    }
  }

  &.on {
    .bun {
      &:before,
      &:after {
        width: 0;
      }

      .burger {
        &:before {
          transform: rotate(135deg);
        }
        &:after {
          transform: rotate(45deg);
          box-shadow: 0 0 0 0 lighten($color, 35);
        }
      }
    }
  }
}

.loading-wrap {
  width: 100%;
  height: 100%;
  display: table;
  position: absolute;
  z-index: 9999;
  background-color: $black-10;
  .loading-box {
    display: table-cell;
    vertical-align: middle;
  }
}

.lds-ring {
  position: relative;
  width: 100px;
  margin: 0 auto;
  height: auto;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 8px;
  border: 3px solid $lime;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $lime transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* etc */

.mr10 {
  margin-right: 10px;
}

.with100 {
  width: 100%;
}

.underLine {
  position: relative;
  z-index: 1;
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 50%;
    right: 50%;
    bottom: -2px;
    background: #fff;
    height: 2px;
    transition: 0.1s;
  }
  &:hover {
    &:before {
      left: 0;
      right: 0;
    }
  }
}
.whiteSpace-pre {
  white-space: pre-line;
}
@keyframes slide_left {
  0% {
    right: 0;
  }
  100% {
    right: 2109px;
  }
}

@mixin clearfix() {
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

@mixin WidthHeight($width, $height) {
  width: $width;
  height: $height;
}

// 토스트
.Toastify {
  .Toastify__toast-container {
    width: auto;
    .Toastify__toast--default {
      background: $lime;
      min-height: auto;
      color: $black-10;
      border-radius: $border-Rl;
      padding: 10px 12px;
    }
    .Toastify__toast-body {
      margin-right: 25px;
      padding: 0;
      font-size: $font-size-s;
      line-height: 1em;
    }
    .Toastify__close-button--default {
      @include WidthHeight(16px, 16px);
      color: $lime;
      opacity: 1;
      border-radius: 16px;
      background: $black-10;
      margin-top: 3px;
      > svg {
        width: 12px;
        height: 12px;
        float: left;
        margin-left: 2px;
      }
    }
  }
}

// 그림자 효과
@mixin box-shadow() {
  box-shadow: 0 25px 30px rgba(0, 0, 0, 0.28);
}

/* 페이드인업 에니메이션 */

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@mixin fadeInUP {
  animation-name: fadeInUp;
  animation-duration: 0.43s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

@mixin fadeInLeft {
  animation-name: fadeInLeft;
  animation-duration: 0.43s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
