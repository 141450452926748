@charset "utf-8";

/* 폰트 */

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@400;700&family=Noto+Sans+KR:wght@400;700&display=swap');
@font-face {
  font-family: 'S-CoreDream-9Black';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-9Black.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@import './common';
@import './reset';
@import './layout/header';
@import './layout/main';
@import './layout/footer';
@import './animate.css';
