
* {
	font-family: 'Lato', 'Noto Sans KR', sans-serif;
	word-break: keep-all;
	padding: 0;
	margin: 0;
	outline: 0; 
	box-sizing: border-box;
}

/* reset */
html,
body,
div,
span,
applet,
object,
iframe,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}
table {
	border-collapse: separate;
	border-spacing: 0;
}
a {
	transition: 0.1s ease;
	text-decoration: none;
	cursor: pointer;
	color: $white-gray;
	line-height: 1.5;
}

a:hover {
	text-decoration: none;
	cursor: pointer;
}

input {
	border: none;
	font-size: 1em;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	/*  -webkit-text-fill-color: #333;*/
	transition: background-color 5000s ease-in-out 0s;
}
input:-ms-autofill,
input:-ms-autofill:hover,
input:-ms-autofill:focus input:-ms-autofill,
textarea:-ms-autofill,
textarea:-ms-autofill:hover textarea:-ms-autofill:focus,
select:-ms-autofill,
select:-ms-autofill:hover,
select:-ms-autofill:focus {
	/*  -ms-text-fill-color: #333;*/
	transition: background-color 5000s ease-in-out 0s;
}
input:-moz-autofill,
input:-moz-autofill:hover,
input:-moz-autofill:focus input:-moz-autofill,
textarea:-moz-autofill,
textarea:-moz-autofill:hover textarea:-moz-autofill:focus,
select:-moz-autofill,
select:-moz-autofill:hover,
select:-moz-autofill:focus {
	/*  -moz-text-fill-color: #333;*/
	transition: background-color 5000s ease-in-out 0s;
}
input[type='button'],
input[type='submit'],
input[type='radio'] {
	-webkit-appearance: none;
	-ms-appearance: none;
	-moz-appearance: none;
	cursor: pointer;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type='number'] {
	-moz-appearance: textfield;
}
input[type='button'],
input[type='submit'] {
	background-color: #fff;
}
button {
	border: none;
	font-size: 1em;
	font-weight: 400;
	cursor: pointer;
	background: transparent;
}
textarea {
	vertical-align: top;
	border: none;
	font-size: 1em;
	resize: none;
	line-height: 1.7em;
}
textarea::-webkit-scrollbar {
	width: 8px;
}
textarea::-webkit-scrollbar-track {
	background-color: #fff;
	margin-top: 2px;
}
textarea::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #ccc;
}
input:focus {
	outline: 0;
}
select:focus {
	outline: 0;
}

body {
	font-size: 16px;
	overflow: auto;
	line-height: 1.5em;
}

.wrap {
	width: 100%;
	float: left;
}

.table {
	display: table;
	width: 100%;
	height: 100%;
}
.middle {
	display: table-cell;
	vertical-align: middle;
}
.left {
	float: left;
}
.right {
	float: right;
}
.pcNone {
	display: none;
}
.moNone {
	display: block;
}

/*GAP*/
.div {
	width: 100%;
	float: left;
	height: 1px;
}

/*FONT*/
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	line-height: 1.5em;
}

p {
	padding: 0;
	margin: 0;
}

/* media query */
@media (max-width: 1024px) {

}
